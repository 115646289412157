body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header, main, footer {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

header.active, main.active, footer.active {
  opacity: 1;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

main {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.domain-info {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.domain-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

button {
  background-color: #25D366;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  padding-left: 30px;
}


button:hover {
  background-color: #128c7e;
  transform: translateY(-2px);
}

.button-icon {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px; /* Ubah ukuran logo sesuai kebutuhan */
}



footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

